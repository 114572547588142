/* You can add global styles to this file, and also import other style files */

@import "common";

html,
body {
    overflow-y: hidden;
}

a.disabled {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;
}

a.disabled:hover {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;

    .content {
        color: gray;
    }
}

.right.floated {
    float: right;
}

.CodeMirror {
    min-height: 40px;
    height: auto;
    border: 1px solid rgba(34, 36, 38, 0.14902);
    border-radius: 4px;
}

// Dragula mirror item  (append on body)
.gu-mirror {
    margin: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.4s ease-in-out;
}

// Code mirror
.CodeMirror-scroll {
    text-align: left;
    height: auto;
    overflow: hidden !important;
}

.fullheight {
    .CodeMirror {
        height: 100% !important;
        max-height: 100%;
    }

    // Code mirror
    .CodeMirror-scroll {
        height: 100% !important;
        max-height: 100%;
        overflow: auto !important;
    }
}

.payload {
    .CodeMirror {
        max-height: 500px;
    }

    .CodeMirror-scroll {
        max-height: 500px;
    }
}

// Override semantic-ui to show warning in form
.ui.form .warning.message,
.ui.form .error.message {
    display: inherit;
}

.message {
    overflow: hidden;
}

$scrollbarsize: 10px;
$scrollbarradius: 0px;

body ::-webkit-scrollbar {
    width: $scrollbarsize;
    height: $scrollbarsize;
}

* ::-webkit-scrollbar {
    width: $scrollbarsize;
    height: $scrollbarsize;
}

body ::-webkit-scrollbar-thumb {
    border-radius: $scrollbarradius;
}

* ::-webkit-scrollbar-thumb {
    border-radius: $scrollbarradius;
}

.toast {
    padding: 0;
    margin: 0;
}

.toast-close-button {
    right: -3px;
}

// dropdown
.ui.buttons {
    sm-dropdown>.ui.dropdown:last-child .menu {
        left: auto;
        right: 0;
    }

    &.right.floated {
        .button:first-child {
            margin-left: 0.25em;
        }
    }
}

.ui.selection.dropdown {
    width: 100%;
    min-width: 0px;
}

.ui.selection.classic.dropdown {
    width: auto;
    min-width: 14em;
}

.ui.circular.labels .label,
.ui.circular.label {
    min-width: inherit;
    min-height: inherit;
}

// icon
.ui {
    i.icon.blue {
        color: $cds_color_teal !important;
    }

    i.icon.red {
        color: $cds_color_red !important;
    }

    i.icon.green {
        color: $cds_color_green !important;
    }
}

// Segment
.ui.green.segment {
    border-top-color: $cds_color_green !important;
}

.ui.red.segment {
    border-top-color: $cds_color_red !important;
}

.ui.blue.segment {
    border-top-color: $cds_color_teal !important;
}

// Button
.ui.green.basic.button {
    background-color: transparent !important;
}

.ui.green.button:active {
    color: #000000;
}

.ui.green.button.loading.disabled {
    opacity: 0.8 !important;
}

.ui.green.button.loading:after {
    border-color: #000000 transparent transparent;
}

.ui.red.button {
    background-color: $cds_color_red !important;
}

.ui.red.basic.button {
    background-color: transparent !important;
}

.ui.red.button:active {
    color: #000000;
}

.ui.red.button.loading.disabled {
    opacity: 0.8 !important;
}

.ui.red.button.loading:after {
    border-color: #000000 transparent transparent;
}

.ui.blue.button {
    background-color: $cds_color_teal !important;
}

.ui.blue.basic.button {
    background-color: transparent !important;
}

.ui.blue.button:active {
    color: #000000;
}

// Column
.centered.column {
    text-align: center;
}

.ui.grid {
    .wide.twelve {
        width: 75% !important;
    }

    .wide.sixteen {
        width: 100% !important;
    }
}

// Ribbon
.ui.green.ribbon {
    background-color: $cds_color_green !important;
    color: black !important;
}

.ui.red.ribbon {
    background-color: $cds_color_red !important;
    color: black !important;
}

.ui.blue.ribbon {
    background-color: $cds_color_teal !important;
    color: black !important;
}

// Circular Label
.ui.blue.circular.label {
    background-color: $cds_color_teal !important;
}

// Loader
.ui.blue.loader:after {
    border-top-color: $cds_color_teal;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

// Field
.right.aligned.field {
    text-align: right;
}

// card
.ui.card {
    width: auto;
}

.ui.card>.content>.description {
    overflow-wrap: break-word;
}

.inline {
    display: inline;
}

// Modal
.ui.modal.basic.warning {
    font-size: 1.25em;
}

// dropdown zindex
.workflow.grid {
    .dropdown.icon {
        position: relative;
        border: 1px solid #cac9c9;
        border-radius: 3px;
        padding: 5px;
        height: 25px;
    }

    .dropdown.icon:active {
        background-color: #cac9c9;
        border-color: white;
        color: white;
    }
}

.pointing,
.pointer {
    cursor: pointer;
}

// Don't know why it's not working on navbar.scss
.navbar {
    .ui.dropdown {
        background-color: transparent;
        border-color: rgba(34, 36, 38, 0.35);

        &.item {
            i {
                color: white;
                opacity: 1;
            }
        }
    }

    .ui.selection.dropdown {
        color: rgba(191, 191, 191, 0.87);
    }

    .ui.right.dropdown {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-left: none;
    }

    .ui.left.dropdown {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

// Toast global settings
.toast-top-right {
    top: $navbarHeight + 10px;
}

.ui.dropdown.nocaret {
    .dropdown.icon {
        display: none;
    }
}

// Hack navbar select language
.ui.dropdown.languageSelect {
    min-width: 1em;
    border: none;

    .dropdown.icon {
        display: none;
    }

    padding-right: 1em;

    >.text>.flag {
        margin-right: 0em;
    }
}

ngui-auto-complete {
    height: 200px;
    overflow-y: auto;
}

ngui-auto-complete .no-match-found {
    display: none;
    border: none;
}

.node {
    white-space: nowrap;
}

.node rect,
.node circle {
    fill: transparent;
}

.node ellipse {
    stroke: #333;
    fill: white;
    stroke-width: 1.5px;
}

.cluster rect,
polygon {
    stroke: #333;
    fill: transparent;
    fill-opacity: 0.1;
    stroke-width: 1.5px;
}

.edgePath path.path {
    stroke: #333;
    stroke-width: 4px;
    fill: none;
}

foreignObject {
    overflow: visible;
}

// Ovveride of ng2-semantic-ui
sui-search {
    .results {
        margin-top: 1.5em !important;
    }

    .ui.input {
        .prompt {
            background-color: rgba(225, 226, 229, 0.1);
            border-color: transparent;

            &:focus {
                border: 1.5px solid #50cfd9;
            }

            color: white;
            opacity: 1;
        }

        i {
            &.search.icon {
                opacity: 1;
            }

            color: white;
            opacity: 1;
        }
    }
}

.codeAdded {
    background-color: #cdffd8;

    .night & {
        background-color: $darkTheme_night_green;
    }
}

.codeRemoved {
    background-color: #ffeef0;

    .night & {
        background-color: $darkTheme_night_red;
    }
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scrollview {
    flex: 1;
}

.buttonBreadcrumb {
    position: relative;
    top: -7px;
}

.bottomBtnsWrapper {
    z-index: 5; // an index to be over all codemirror elements
}
