@import "theme";
$darkBackground: #1b1c1d;
$greyBackground: rgba(249, 249, 249, 1);
$darkGreyColor: rgba(34, 36, 38, 0.15);
$navbarHeight: 50px;
$sidebarOpenWidth: 0px;
$sidebarCloseWidth: 0px;
$cds_color_green: #21BA45;
$cds_color_teal: #4fa3e3;
$cds_color_red: #FF4F60;
$cds_color_orange: #FE9A76;
$cds_color_light_green: #E5FFE5;
$cds_color_light_red: #FFD2D6;
$cds_color_light_teal: #BEDEFF;
$cds_color_light_grey: #EEEEEE;
$cds_trigger_color: #9a9a9a;
$cds_favorite_color: #f4d041;
$polar_grey_0: #333333;
$polar_grey_1: #666666;
$polar_grey_2: #6B6F7B;
$polar_grey_3: #B5B7BD;
$polar_grey_4: #E1E2E5;
$polar_grey_5: #FCFCFC;
$spinkit-spinner-color: $cds_color_teal;

.word-break-all {
    word-break: break-all;
}

.CodeMirror-hints {
    z-index: 1002; // for payload suggestion in modals
}

pre {
    .string {
        color: green;
    }

    .number {
        color: darkorange;
    }

    .boolean {
        color: blue;
    }

    .null {
        color: magenta;
    }

    .key {
        color: red;
    }
}

// View style
.CDSmenu {
    padding: 0.25em 30px 0 30px;
    height: 40px;
    margin-bottom: .3em;
}

.scrollingContent {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    flex: 1;

    .paddingContent {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 0px 100px 0px 100px;

        .viewContent {
            padding-top: 25px;
        }
    }
}

// End view style
// Center a field
.field.center {
    text-align: center;
}

// Table
.table {
    border: none;

    thead th {
        border: none;
    }

    tbody tr td:first-child {
        border-left: 1px solid $darkGreyColor;

        :host-context(.night) & {
            border-color: $darkTheme_grey_3;
        }
    }

    tbody tr td:last-child {
        border-right: 1px solid $darkGreyColor;

        :host-context(.night) & {
            border-color: $darkTheme_grey_3;
        }
    }

    tbody tr:first-child td {
        border-top: 1px solid $darkGreyColor;

        :host-context(.night) & {
            border-color: $darkTheme_grey_3;
        }
    }

    tbody tr:last-child td {
        border-bottom: 1px solid $darkGreyColor;

        :host-context(.night) & {
            border-color: $darkTheme_grey_3;
        }
    }

    tbody tr td {
        border: none;
        overflow: visible;
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: middle;
    }

    tbody tr td.border {
        border-top: 1px solid rgba(34, 36, 38, 0.15);
    }
}
.ui.table th.center {
    text-align: center;
}

.ui.table td.center {
    text-align: center;
}

.xcentered {
    left: 50%;
    transform: translateX(-50%);
}

.ycentered {
    left: 50%;
    transform: translateX(-50%);
}

// End table
