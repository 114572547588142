// This file contains all the overrides on semantic components required for CDS light and night theme.
$darkBackground: #1b1c1d;

$darkTheme_grey_0: #2d2c2c; // top bar and active|hover on darkTheme_grey_1 background
$darkTheme_grey_1: #383736;
$darkTheme_grey_2: #333333;
$darkTheme_grey_3: #434141;
$darkTheme_grey_4: #494949;
$darkTheme_grey_5: #a2a3a3; // title on darkTheme_grey_1
$darkTheme_grey_6: #cccccc; // text on darkTheme_grey_1

$darkTheme_grey_inactive: #676767;

$darkTheme_green: #007611;
$darkTheme_blue: #0070d9;
$darkTheme_red: #e42805;
$darkTheme_orange: #ff7924;
$darkTheme_grey: #a2a3a3;
$darkTheme_light_green: #94eaa0;
$darkTheme_light_blue: #61b3ff;
$darkTheme_light_red: #f5a293;
$darkTheme_light_orange: #f9aa7a;
$darkTheme_light_grey: #cfd0d0;
$darkTheme_night_green: #115e00;
$darkTheme_night_red: #8a1b02;
$darkTheme_night_blue: #003f7e;
$darkTheme_night_grey: #494949;
$darkTheme_night_orange: #9e4607;

a,
.ui.breadcrumb a,
.ui.list>a.item {
    .night & {
        color: #49a7ff;
    }
}

.ui.list .list>.disabled.item,
.ui.list>.disabled.item {
    .night & {
        color: $darkTheme_grey_4 !important;
    }
}

.ui.list>.item>.content,
.ui.list>.item>.content>.description {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.night {
    color: $darkTheme_grey_6;
    background-color: $darkTheme_grey_1;
}

.ui.card {
    .meta {
        .night & {
            color: rgba(255, 255, 255, 0.4);
        }
    }

    .night & {
        background-color: $darkTheme_grey_0;
        box-shadow: 0px 1px 3px 0px $darkTheme_grey_2,
            0px 0px 0px 1px $darkTheme_grey_2;
    }
}

.ui.cards>.card {
    .night & {
        background-color: $darkTheme_grey_0;
        box-shadow: 0px 1px 3px 0px $darkTheme_grey_2,
            0px 0px 0px 1px $darkTheme_grey_2;
    }
}

.ui.cards>.card>.content>.header {
    .night & {
        color: $darkTheme_grey_5;
    }
}

.ui.cards>.card .meta,
.ui.cards>.card>.content>.description {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.cards a.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover,
.ui.link.card:hover {
    .night & {
        background-color: $darkBackground;
        box-shadow: 0px 1px 3px 0px $darkTheme_grey_2,
            0px 0px 0px 1px $darkTheme_grey_2;
    }
}

.ui.card>.content>.header {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
    .night & {
        border-color: $darkTheme_grey_2;
    }
}

.ui.selection.dropdown {
    .night & {
        background-color: $darkTheme_grey_0;
        color: $darkTheme_grey_6;
        border-color: $darkTheme_grey_2;
    }
}

.ui.selection.dropdown .menu>.item {
    .night & {
        border-color: $darkTheme_grey_2;
    }
}

.ui.menu {
    .night & {
        background-color: $darkTheme_grey_3;
        box-shadow: none;
    }
}

.ui.menu .item {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
    color: $darkTheme_grey_5;
}

.ui.pagination.menu .active.item {
    .night & {
        color: $darkTheme_grey_5;
    }
}

.ui.ui.menu .item.disabled {
    .night & {
        color: $darkTheme_grey_inactive;
    }
}

.ui.secondary.menu {
    .night & {
        background-color: $darkTheme_grey_1;
    }
}

.ui.vertical.menu {
    background-color: transparent !important;
}

.ui.vertical.pointing.menu .active.item:after {
    .night & {
        background-color: $darkTheme_grey_2;
    }
}

.ui.vertical.pointing.menu .item:after {
    .night & {
        border-color: $darkTheme_grey_2;
    }
}

.ui.secondary.pointing.menu {
    .night & {
        border-color: $darkTheme_grey_4;
    }
}

.ui.secondary.pointing.menu .active.item {
    .night & {
        color: $darkTheme_grey_6;
        border-color: $darkTheme_grey_5;
    }
}

.ui.breadcrumb .divider {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.segment {
    .night & {
        background-color: $darkTheme_grey_0;
    }
}

.ui.form .field>label {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.form .inline.fields .field>label {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.input {
    color: inherit;
}

input:read-only {
    opacity: 0.45;
}

.ui.form input,
.ui.form textarea,
.ui.input input {
    .night & {
        color: $darkTheme_grey_6;
        background-color: $darkTheme_grey_0;
        border-color: $darkTheme_grey_2;
    }
}

.ui.form input:focus,
.ui.form textarea:focus {
    .night & {
        color: $darkTheme_grey_6;
        background-color: $darkBackground;
        border-color: $darkTheme_grey_2;
    }
}

.ui.header {
    .night & {
        color: $darkTheme_grey_5;
    }
}

.ui.header .sub.header {
    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.attached.header,
.ui.attached.block.header,
.ui.attached.top.segment {
    .night & {
        color: $darkTheme_grey_6;
        background-color: $darkTheme_grey_1;
        border-color: $darkTheme_grey_1;
    }
}

.ui.attached.segment {
    .night & {
        border-color: $darkTheme_grey_1;
    }
}

.ui.label {
    .night & {
        color: $darkTheme_grey_6;
        background-color: $darkTheme_grey_3;
    }
}

.ui.basic.label,
.ui.basic.green.label,
.ui.basic.orange.label,
.ui.basic.grey.label {
    background-color: transparent !important;
}

.ui.basic.grey.label {
    .night & {
        color: $darkTheme_grey;
        border-color: $darkTheme_grey;
    }
}

.ui.basic.green.label {
    .night & {
        color: $darkTheme_green;
        border-color: $darkTheme_green;
    }
}

.ui.basic.orange.label {
    .night & {
        color: $darkTheme_orange;
        border-color: $darkTheme_orange;
    }
}

.ui.basic.blue.label {
    .night & {
        color: $darkTheme_blue;
        border-color: $darkTheme_blue;
    }
}

.ui.button {
    .night & {
        background-color: $darkTheme_grey;
    }
}

.ui.positive.button,
.ui.green.button,
.ui.green.buttons .button {
    .night & {
        background-color: $darkTheme_green;
    }
}

.ui.basic.green.button {
    .night & {
        box-shadow: 0px 0px 0px 1px $darkTheme_green inset !important;
        color: $darkTheme_green !important;
    }
}

.ui.basic.blue.button {
    .night & {
        box-shadow: 0px 0px 0px 1px $darkTheme_blue inset !important;
        color: $darkTheme_blue !important;
    }
}

.ui.negative.button,
.ui.red.button {
    .night & {
        background-color: $darkTheme_red !important;
    }
}

.ui.primary.button,
.ui.blue.button {
    .night & {
        background-color: $darkTheme_blue !important;
    }
}

.ui.grey.button {
    .night & {
        background-color: $darkTheme_grey !important;
    }
}

a.ui.basic.green.label:hover,
a.ui.basic.orange.label:hover,
a.ui.basic.grey.label:hover {
    background-color: transparent !important;
}

.ui.steps,
.ui.steps .step {
    .night & {
        background-color: $darkTheme_grey_2;
        border-color: $darkTheme_grey_3;
        color: $darkTheme_grey_6;
    }
}

.ui.steps .step:after {
    .night & {
        background-color: $darkTheme_grey_2;
        border-color: $darkTheme_grey_3;
    }
}

.ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
    .night & {
        color: inherit;
    }
}

.ui.steps .step .description {
    .night & {
        color: inherit;
    }
}

.ui.steps .disabled.step {
    .night & {
        color: $darkTheme_grey_inactive;
    }
}

.ui.steps .disabled.step .title {
    .night & {
        color: inherit;
    }
}

.ui.steps .disabled.step .description {
    .night & {
        color: inherit;
    }
}

.ui.steps .step.active {
    .night & {
        background-color: $darkTheme_grey_4 !important;
        color: $darkTheme_grey_6;
    }
}

.ui.steps .step.active:after {
    .night & {
        background-color: $darkTheme_grey_4;
        border-color: $darkTheme_grey_3;
    }
}

.table {
    background-color: transparent !important;

    .night & {
        color: $darkTheme_grey_6;
    }
}

.table thead th,
.ui.table thead th {
    color: grey;
    background-color: transparent !important;

    .night & {
        color: $darkTheme_grey_6;
    }
}

.ui.table tr.active {
    .night & {
        background-color: $darkTheme_grey_4 !important;
        color: $darkTheme_grey_6;
    }
}

.ui.popup {
    .night & {
        background-color: $darkTheme_grey_1;
        border-color: $darkTheme_grey_5;
        color: $darkTheme_grey_6;

        .arrow {
            background-color: $darkTheme_grey_1;
        }
    }
}

.ui.modal,
.ui.modal>.content {
    .night & {
        background-color: $darkTheme_grey_1;
    }
}

.ui.modal>.header,
.ui.modal>.actions,
.ui.dropdown .menu {
    .night & {
        background-color: $darkTheme_grey_0;
        color: $darkTheme_grey_5;

        .item {
            color: $darkTheme_grey_6;
        }
    }
}

.ui.pointing.dropdown>.menu:not(.hidden):after {
    .night & {
        background-color: $darkTheme_grey_0;
    }
}

.ui.modal>.close {
    .night & {
        color: $darkTheme_grey_5;
    }
}

.ui.menu .dropdown.item .menu {
    .night & {
        background-color: $darkTheme_grey_0;
    }
}

.ui.menu .dropdown.item .menu>.item {
    .night & {
        color: $darkTheme_grey_6 !important;
    }
}

.ui.toggle.checkbox input:checked~label,
.ui.toggle.checkbox label,
.ui.checkbox label {
    .night & {
        color: $darkTheme_grey_6 !important;
    }
}

.ui.message {
    .night & {
        color: $darkTheme_light_grey;
        background-color: $darkTheme_night_grey;
        box-shadow: 0px 0px 0px 1px $darkTheme_light_grey inset,
            0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
}

.ui.info.message,
.ui.blue.message {
    .night & {
        color: $darkTheme_light_blue;
        background-color: $darkTheme_night_blue;
        box-shadow: 0px 0px 0px 1px $darkTheme_light_blue inset,
            0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
}

.ui.warning.message,
.ui.orange.message {
    .night & {
        color: $darkTheme_light_orange;
        background-color: $darkTheme_night_orange;
        box-shadow: 0px 0px 0px 1px $darkTheme_light_orange inset,
            0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
}

.ui.red.message {
    .night & {
        color: $darkTheme_light_red;
        background-color: $darkTheme_night_red;
        box-shadow: 0px 0px 0px 1px $darkTheme_light_red inset,
            0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
}

.ui.success.message,
.ui.green.message {
    .night & {
        color: $darkTheme_light_green;
        background-color: $darkTheme_night_green;
        box-shadow: 0px 0px 0px 1px $darkTheme_light_green inset,
            0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
}

.ui.search>.results .result .title {
    .night & {
        color: $darkTheme_grey_5;
    }
}

.ui.search>.results .result:hover {
    .night & {
        background-color: $darkBackground;
    }
}

.ui.search>.results {
    .night & {
        background-color: $darkTheme_grey_0;
        border-color: $darkTheme_grey_1;
    }
}

.ui.inverted.red.segment {
    .night & {
        background-color: $darkTheme_red !important;
    }
}

.ui.styled.accordion {
    background-color: transparent;

    .night & {
        box-shadow: 0px 1px 2px 0 $darkTheme_grey_3,
            0px 0px 0px 1px $darkTheme_grey_3;
    }
}

.ui.styled.accordion .title {
    .night & {
        color: $darkTheme_grey_5;
        border-color: $darkTheme_grey_3;
    }
}

.ui.tabular.menu {
    .night & {
        border-color: $darkTheme_grey_1;
    }
}

.ui.tabular.menu .active.item {
    .night & {
        background-color: $darkTheme_grey_0;
        border-color: $darkTheme_grey_1;
        color: $darkTheme_grey_6;
    }
}

.loading .ui.dimmer {
    background-color: unset;
}
